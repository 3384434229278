import * as React from "react"
import '../styles/styles.css';
import JeSemeImg from "../images/je-seme.jpg";
import useSticky from "../hooks/useSticky";
import Navigation from "../components/navigation";
import Mapbox from "../components/mapbox";
import { FaFacebook, FaInstagram, FaRegEnvelope } from 'react-icons/fa';
import Titre from "../components/titre";
import { Helmet } from "react-helmet";
import Evenements from "../components/evenements";

// styles
const pageStyles = {
  // color: "#232129",
  // padding: 96,
  // fontFamily: "-apple-system, Roboto, sans-serif, serif",
  // maxWidth: '1400px',
  margin: 'auto auto',
}

// markup
const IndexPage = () => {
  const { isSticky, element } = useSticky();
  return (
    <div style={pageStyles}>
      <Helmet>
        {/* <script async defer crossorigin="anonymous" src="https://connect.facebook.net/en_US/sdk.js"></script> */}
        <title>Home Page</title>
      </Helmet>
      <header>
        <Titre></Titre>
        <Navigation sticky={isSticky}></Navigation>
      </header>
      <main>
        <section id="presentation" ref={element}>
          <div className="flou">
            <h2 style={{ display: "none" }}>Présentation</h2>
            <p>Sénevé est une <em><b>église chrétienne</b></em> fondée en 2019 à <em><b>Cherbourg-en-Cotentin</b></em>.</p>
          </div>
        </section>
        <section className="section" id="evenements">
          <h2>Événements</h2>
          {/* <p>Retrouvez bientôt nos événements dans l'Unité.</p> */}
          <Evenements></Evenements>
        </section>

        <section className="yellow section" id="je-seme">
          <div className="text">
            <h2>Je sème</h2>
            <p>Si vous désirez nous aider et soutenir l'action de Sénevé dans le Cotentin et au-delà, nous voulons vous donner la possibilité de donner financièrement.</p>
            <p>Chaque don nous permettra d'organiser de nouveaux événements et des rencontres pour louer Dieu ensemble.</p>
            <div className="bouton-block">
              <p><a className="bouton bleu" href="https://www.helloasso.com/associations/seneve/formulaires/1/widget">Donner</a></p>
            </div>
          </div>
          <div className="img">
            <img src={JeSemeImg} alt="" />
          </div>
        </section>

        <section className="section" id="restons-connectes">
          <h2>Restons connectés</h2>
          <p>Nous nous retrouvons <em><b>chaque dimanche</b></em> de l'année scolaire <em><b>à 10h30</b></em> pour un culte protestant évangélique.</p>
          <div className="flex-conteneur">
            <div className="flex-gauche">
              <p>Contactez-nous pour toute <em><b>demande d'information</b></em> sur nos rencontres, pour nous partager un <em><b>témoignage</b></em> de l'action de Dieu dans votre vie ou pour demander la <em><b>prière</b></em> :</p>
              <div className="bouton-block">
                <p><a className="bouton" href="mailto:contact@seneve.org">Nous contacter</a></p>
                <p><a className="bouton" href="mailto:temoignage@seneve.org">Témoigner</a></p>
                <p><a className="bouton" href="mailto:priere@seneve.org">Demander la prière</a></p>
              </div>
            </div>
            <div>
              <hr className="flex-separateur" />
            </div>
            <div className="flex-droite">
              <p>Vous pouvez aussi connecter avec l'église Sénevé par la <em><b>lettre de nouvelles</b></em> et nous suivre sur nos <em><b>réseaux sociaux</b></em> :</p>
              <div className="bouton-block">
                <p><a className="bouton" href="https://newsletter.seneve.org"><FaRegEnvelope />&nbsp;Newsletter</a></p>
                <p><a className="bouton facebook" href="https://www.facebook.com/seneve.org"><FaFacebook />&nbsp;Facebook</a></p>
                <p><a className="bouton instagram" href="https://www.instagram.com/seneve_org/"><FaInstagram />&nbsp;Instagram</a></p>
              </div>
            </div>
          </div>
        </section>
        <Mapbox></Mapbox>
      </main>
      <footer className="section">
        <div>
          <Titre></Titre>
        </div>
        {/* <hr /> */}
        <div>
          <p>Navigation :</p>
          <Navigation></Navigation>
        </div>
        {/* <hr /> */}
        <div>
          <p>Nous suivre :</p>
          <p><a className="bouton" href="https://newsletter.seneve.org"><FaRegEnvelope />&nbsp;Newsletter</a></p>
          <p><a className="bouton facebook" href="https://www.facebook.com/seneve.org"><FaFacebook />&nbsp;Facebook</a></p>
          <p><a className="bouton instagram" href="https://www.instagram.com/seneve_org/"><FaInstagram />&nbsp;Instagram</a></p>
        </div>
      </footer>
    </div>
  )
}

export default IndexPage
