import React, { useRef, useEffect, useState } from 'react';

const Titre = ({ element }) => {

    return (
        <a href="/" ref={element}>
            <h1 className="site-title">
                Sénevé
                <br />
                <span className="site-description">— Au service de l'Église </span>
                <span role="img" aria-label="church emoji">
                    ⛪️
                </span>
            </h1>
        </a>
    );
}

export default Titre;